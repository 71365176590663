import firebase from 'firebase';
import firebaseConfig from './config';

export default {
  init() {
    firebase.initializeApp(firebaseConfig);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  },
};
//   firebase.analytics();
