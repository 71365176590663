import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { auth, modalDialog, pinList } from './modules';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    modalDialog,
    pinList,
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
