<template>
  <gmap-map
    :center="center"
    v-bind:zoom="14"
    style="width: 100%; height: 800px"
  >
    <gmap-polyline
      v-bind:path.sync="path"
      v-bind:options="{ strokeColor: polyline_color }"
    >
    </gmap-polyline>

    <gmap-marker
      :key="index"
      v-for="(m, index) in getPinList"
      :position="m.position"
      :clickable="true"
      :draggable="true"
      @click="center = m.position"
    >
    </gmap-marker>
  </gmap-map>
</template>

<script>
// import { pinList } from '../../store/modules';
import colors from './../Const';
export default {
  data() {
    return {
      center: { lat: 34.684346, lng: 135.836375 },
      path: [],
      polyline_color: colors.polylineColor,
    };
  },
  computed: {
    getPinList() { 
      const pinListData = this.$store.getters['pinList/pinListData'];
      const markers = this.pinAdd(pinListData);
      return markers;
    }
  },
  methods: {
    pinAdd(data) {
      const markers = [];
      let sumLat = 0;
      let sumLong = 0;
      data.forEach((element) => {
        markers.push({
          position: {
            lat: element.Lat,
            lng: element.Long,
          }
        })
        sumLat += element.Lat;
        sumLong += element.Long;
      });
      // 表示中心位置を更新
      this.center.lat = sumLat / markers.length;
      this.center.lng = sumLong / markers.length;
      return markers;
    },
  }
};
</script>
