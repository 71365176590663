const firebaseConfig = {
  apiKey: 'AIzaSyDRpCkA1yH9Fr9yHcqwZIkmCLR7deHZgCU',
  authDomain: 'signagenavigation.firebaseapp.com',
  databaseURL: 'https://signagenavigation-default-rtdb.firebaseio.com',
  projectId: 'signagenavigation',
  storageBucket: 'signagenavigation.appspot.com',
  messagingSenderId: '900361120049',
  appId: '1:900361120049:web:5dc5672c8c5ff2dd656810',
  measurementId: 'G-VN5Y7RZH3C',
};

export default firebaseConfig;
