<template>
  <v-app>
    <!-- <v-row> -->
    <!-- <div v-if="isAuthenticated"> -->
    <!-- <v-btn outlined color="gray" @click="logout" data-cy="logout">Logout</v-btn> -->
    <!-- </div> -->
    <!-- </v-row> -->
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/userSignOut');
    },
  },
};
</script>
