import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import Signin from '../components/pages/Signin';
import HomePage from '@/components/pages/HomePage';
import PrivacyPolicy from "@/components/pages/PrivacyPolicy";
import UserPolicy from "@/components/pages/UserPolicy";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/policy/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/policy/user',
    name: 'UserPolicy',
    component: UserPolicy,
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {
    if (store.getters['auth/isAuthenticated']) {
      next();
    } else {
      next({ name: 'Signin' });
    }
  } else {
    next();
  }
});

export default router;
