export default {
  namespaced: true,
  state: {
    isShowCsvDialog: false,
    isShowClearListDialog: false,
    dialogMessages: {},
  },
  mutations: {
    setIsShowCsvDialog(state, payload) {
      state.isShowCsvDialog = payload;
    },
    setIsShowClearListDialog(state, payload) {
      state.isShowClearListDialog = payload;
    },
    setDialogMessages(state, payload) {
      state.dialogMessages = payload;
    },
  },
  actions: {
    continueDialog({ commit, getters, rootGetters }) {
      // CSVロード
      if (getters['isShowCsvDialog']) {
        commit('setIsShowCsvDialog', false);
        let csvData = rootGetters['pinList/csvData'];
        let pinListData = rootGetters['pinList/pinListData'];

        commit('pinList/setPinListData', pinListData.concat(csvData), {
          root: true,
        });

        // ピンリスト削除
      } else if (getters['isShowClearListDialog']) {
        commit('setIsShowClearListDialog', false);
        commit('pinList/setPinListData', [], { root: true });
      }
    },
    cancelDialog({ commit }) {
      commit('setIsShowCsvDialog', false);
      commit('setIsShowClearListDialog', false);
    },
  },
  modules: {},
  getters: {
    isShowCsvDialog(state) {
      return state.isShowCsvDialog;
    },
    isShowClearListDialog(state) {
      return state.isShowClearListDialog;
    },
    dialogMessages(state) {
      return state.dialogMessages;
    },
  },
};
