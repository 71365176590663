<template>
  <v-app>
    <v-content>
      <v-container fluid class="px-16 py-8">
        <v-row>
          <v-col cols="4" class="page-left" align-content="center">
            <v-row class="page-left-item mb-6">
              <v-col>
                <text-form />
              </v-col>
            </v-row>
            <v-row class="page-left-item">
              <v-col>
                <pin-list :pinlist="pinList" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8" class="page-map">
            <Map />
          </v-col>
          <ModalDialog />
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Map from '../organisms/Map';
import TextForm from '../organisms/TextForm.vue';
import PinList from '../organisms/PinList.vue';
import ModalDialog from '../organisms/ModalDialog.vue';

export default {
  name: 'App',
  components: {
    Map,
    TextForm,
    PinList,
    ModalDialog,
  },

  data() {
    return {
      pinList: [],
    };
  },
};
</script>
