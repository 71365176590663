import firebase from 'firebase';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    user: {},
    isAuthenticated: false,
    isAdmin: false,
    authErrorMessages: false,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setIsAdmin(state, payload) {
      state.isAdmin = payload;
    },
    setIsAuthErrorMessages(state, payload) {
      state.isAuthErrorMessages = payload;
    },
  },
  actions: {
    userLogin({ dispatch, commit, state }) {
      const userInfo = firebase.auth().currentUser;
      const uid = userInfo.uid;

      commit('setIsAuthErrorMessages', false);
      const docRef = firebase
        .firestore()
        .collection('users')
        .doc(uid);

      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            commit('setUser', userInfo);
            commit('setIsAuthenticated', !!userInfo.uid);

            router.push('/');
            if (doc.data().is_admin === true) {
              // admin時
              commit('setIsAdmin', true);
            } else {
              // 非admin時
              commit('setIsAdmin', false);
            }
          } else {
            // ユーザ作られてないのでエラーメッセージ表示
            dispatch('userSignOut', state.current_condition).then(() => {
              commit('setIsAuthErrorMessages', true);
              router.go();
            });
          }
        })
        .catch(error => {
          console.log('Error getting document:', error);
        });
    },
    userSignOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit('setUser', {});
          commit('setIsAuthenticated', false);
          commit('setIsAdmin', false);
          router.push('/signin');
        })
        .catch(() => {
          router.push('/');
        });
    },
    authAction({ commit }) {
      firebase.auth().onAuthStateChanged(user => {
        user = user || {};
        commit('setUser', user);
        commit('setIsAuthenticated', !!user.uid);
      });
    },
  },
  modules: {},
  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isAuthErrorMessages(state) {
      return state.isAuthErrorMessages;
    },
  },
};
