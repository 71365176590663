export default {
  namespaced: true,
  state: {
    pinListData: [],
    csvData: [],
  },
  mutations: {
    setPinListData(state, payload) {
      state.pinListData = payload;
    },
    setCSVData(state, payload) {
      state.csvData = payload;
    },
  },
  actions: {
    clearPinList({ commit }) {
      commit('modalDialog/setIsShowClearListDialog', true, { root: true });
      commit('modalDialog/setDialogMessages', '本当に削除しますか？', {
        root: true,
      });
    },
    loadCSV({ commit }, { e }) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const dataObjArray = [];
        const dataStr = reader.result.split('\n');

        const [header, ...dataArray] = dataStr
          .filter(row => {
            if (row !== '') {
              return row;
            }
          })
          .map(row => {
            return row.split(',');
          });

        for (let i = 0; i < dataArray.length; i++) {
          const csvDataObj = {
            和名: '',
            英名: '',
            属性: '',
            Lat: '',
            Long: '',
          };
          for (let j = 0; j < header.length; j++) {
            if (csvDataObj[header[j].replace('\r', '')] != undefined) {
              if (header[j].replace('\r', '') === 'Lat' || header[j].replace('\r', '') === 'Long'){
                csvDataObj[header[j].replace('\r', '')] = parseFloat(dataArray[i][j]);
              } else {
                csvDataObj[header[j].replace('\r', '')] = dataArray[i][j];
              }
            } else {
              //TODO: エラー処理
              console.log('error');
            }
          }
          dataObjArray.push(csvDataObj);
        }
        console.log('dataObjArray', dataObjArray);
        //ダイアログに本文追加
        commit(
          'modalDialog/setDialogMessages',
          dataObjArray.length + '件のデータを追加します。よろしいですか？',
          { root: true }
        );
        commit('setCSVData', dataObjArray);

        //モーダルダイアログを開く
        commit('modalDialog/setIsShowCsvDialog', true, { root: true });
        document.getElementById('input').value = '';
      };
    },
  },
  modules: {},
  getters: {
    pinListData(state) {
      return state.pinListData;
    },
    csvData(state) {
      return state.csvData;
    },
  },
};
