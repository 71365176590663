<template>
  <v-container>
    <v-row justify="center">
      <v-flex xs12 sm12 md8 lg8 x6>
        <h1 class="font-weight-bold mb-2 text-left display-1 mt-10">
          プライバシーポリシー
        </h1>
        <v-divider></v-divider>
        <v-row justify="start">
          <p class="subheading font-weight-regula ml-6 mr-6 mt-5 text-left">
            SCI-CO合同会社（以下，「当社」といいます。）は，本アプリ上で提供するサービス（以下,「本サービス」といいます。）における，
            ユーザの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
          </p>
        </v-row>
        <v-row class="ml-4 mr-4">
          <v-col>
            <section class="align-center mt-6">
              <h2 class="font-weight-bold text-left headline">
                第1条（個人情報）
              </h2>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって、
                当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び
                容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
              </p>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left text-left">
                本アプリは，以下の個人情報を取得します．
              </p>
              <ol class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                <li>ユーザーの生年月日・性別</li>
                <li>ユーザーの名前(本人特定ができないニックネームを含む)</li>
                <li>ユーザのメールアドレス</li>
              </ol>
            </section>

            <section class="align-left mt-10">
              <h2 class="font-weight-bold text-left headline">
                第2条（個人情報の収集方法）
              </h2>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。
                また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
              </p>
            </section>


            <section class="align-left mt-10">
              <h2 class="font-weight-bold text-left headline">
                第3条（個人情報を収集・利用する目的）
              </h2>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                当社が個人情報を収集・利用する目的は，以下のとおりです。
              </p>
              <ol class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                <li>当社サービスの提供・運営のため</li>
                <li>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
                <li>ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</li>
                <li>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</li>
                <li>利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</li>
                <li>ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</li>
                <li>ユーザーへの金銭授受の際に必要な情報を保存しておくため</li>
                <li>上記の利用目的に付随する目的</li>
              </ol>
            </section>

            <section class="align-center mt-10">
              <h2 class="font-weight-bold text-left headline">
                第4条（利用目的の変更）
              </h2>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
                利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザに通知し，または本ウェブサイト上に公表するものとします。
              </p>
            </section>

            <section class="align-center mt-10">
              <h2 class="font-weight-bold text-left headline">
                第5条（個人情報の第三者提供）
              </h2>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
              </p>
            </section>

            <section class="align-center mt-10">
              <h2 class="font-weight-bold text-left headline">
                第6条（個人情報の開示）
              </h2>
              <ol class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                <li>
                  当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。
                  ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，
                  開示しない決定をした場合にはその旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1,000円の手数料を申し受けます。
                  <ol class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                    <li>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</li>
                    <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                    <li>その他法令に違反することとなる場合</li>
                  </ol>
                </li>
                <li>前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</li>
              </ol>
            </section>

            <section class="align-center mt-10">
              <h2 class="font-weight-bold text-left headline">
                第7条（個人情報の訂正および削除）
              </h2>
              <ol class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                <li>
                  ユーザは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
                </li>
                <li>
                  当社は，ユーザから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
                </li>
                <li>
                  当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザに通知します。
                </li>
              </ol>
            </section>

            <section class="align-center mt-10">
              <h2 class="font-weight-bold text-left headline">
                第8条（個人情報の利用停止等）
              </h2>
              <ol class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                <li>
                  当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
                </li>
                <li>
                  前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
                </li>
                <li>
                  当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザに通知します。
                </li>
                <li>
                  前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
                </li>
              </ol>
            </section>

            <section class="align-center mt-10">
              <h2 class="font-weight-bold text-left headline">
                第9条（プライバシーポリシーの変更）
              </h2>
              <ol class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                <li>
                  本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザに通知することなく，変更することができるものとします。
                </li>
                <li>
                  当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
                </li>
              </ol>
            </section>

            <section class="align-center mt-10">
              <h2 class="font-weight-bold text-left headline">
                第11条（お問い合わせ窓口）
              </h2>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
              </p>
              <p class="subheading font-weight-regula ml-6 mr-6 mt-3 text-left">
                〒105-0013<br>
                東京都港区浜松町２丁目２番１５号 浜松町ダイヤビル２Ｆ<br>
                SCI-CO合同会社<br>
                info@sci-co.co.jp
              </p>
            </section>
          </v-col>
        </v-row>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>
