<template>
  <!-- ダイアログの定義 -->
  <v-dialog v-model="isShowDialog" max-width="500">
    <v-card>
      <v-card-title class="headline">確認</v-card-title>
      <v-card-text>{{ dialogBody }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="cancelDialog">キャンセル</v-btn>
        <v-btn @click="continueDialog">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '../../store';

export default {
  name: 'ModalDialog',
  computed: {
    isShowDialog() {
      return store.getters['modalDialog/isShowCsvDialog'] ||
        store.getters['modalDialog/isShowClearListDialog']
        ? true
        : false;
    },
    dialogBody() {
      return store.getters['modalDialog/dialogMessages'];
    },
  },
  mounted() {},
  methods: {
    cancelDialog() {
      store.dispatch('modalDialog/cancelDialog');
      return false;
    },
    continueDialog() {
      store.dispatch('modalDialog/continueDialog');
      return false;
    },
  },
};
</script>
