<template>
  <v-content>
    <v-container justify-center>
      <div v-if="isAuthErrorMessages">
        <v-alert dense outlined type="error">
          Nothing User Account
        </v-alert>
      </div>
      <div id="firebaseui-auth-container" />
    </v-container>
  </v-content>
</template>

<script>
import store from '../../store';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

export default {
  name: 'Signin',
  computed: {
    isAuthErrorMessages() {
      return store.getters['auth/isAuthErrorMessages'];
    },
  },
  mounted() {
    // FirebaseUI config.
    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function() {
          store.dispatch('auth/userLogin');
          return false;
        },
      },
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        'apple.com',
      ],

      tosUrl: '/category',

      privacyPolicyUrl: function() {
        window.location.assign('/privacy');
      },
    };

    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', uiConfig);
  },
};
</script>
