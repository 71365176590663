<script>
const colors = {
  // Text
  textColorLight: '0xfffff5f5',
  textColorPrimary: '0xFFFF9800',
  textColorDark: '0x8A000000',

  // Global
  primaryColor: '#FF9800',
  middleColor: '0xFFFFB74D',
  secondaryColor: '0xFFFFE0B2',
  lightColor: '0xFFFFFFFF',
  accentColor: '0xFFD32F2F',
  disableColor: '0xFF8A8A8A',

  // Shape
  borderColor: '0xFFFFFFFF',
  barColor: '0xFFE65100',
  loadingCircleColor: '0xFFFF9800',

  // Form
  messageColor: '0x73000000',
  successColor: '0xFF43A047',
  errorColor: '#d32f2f',

  // cupertino
  cupertinoColor: '0xFFFFFFFF',

  // background
  normalBackgroundColor: '0xFFFFFAF0',
  grayBackgroundColor: '0xFFEFEFEF',
  darkBackgroundColor: '0xFF707070',
  transDarkBackgroundColor: '0xa0707070',

  // card
  normalCardColor: '0xFFFFFFFF',
  failedCardColor: '0xFFFFEBEE',
  succeededCardColor: '0xFFC8E6C9',

  // icon
  iconColor: '0x8A000000',

  // map
  polylineColor: '#0000ff',
};

export default colors;
</script>
