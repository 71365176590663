<template>
  <v-row>
    <v-col cols="12">
      <v-data-table :headers="headers" :items-per-page="5" :items="getPinList">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="removePinData(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import store from '../../store';

export default {
  props: ['pinlist'],
  data() {
    return {
      headers: [
        { text: 'エリア', value: '和名', width: '40%' },
        {
          text: '属性',
          value: '属性',
          width: '20%',
        },
        { text: '緯度', value: 'Lat', width: '15%' },
        { text: '経度', value: 'Long', width: '15%' },
        {
          text: '削除',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '10%',
        },
      ],
    };
  },
  computed: {
    items() {
      let pinListData = store.getters['pinList/pinListData'].map(data => ({
        area: data['和名'],
        type: data['属性'],
        latitude: data['Lat'],
        longitude: data['Long'],
      }));
      return pinListData;
    },
    getPinList() {
      return this.$store.getters['pinList/pinListData'];
    },
  },
  methods: {
    removePinData(pinData) {
      const removePinDataIndex = this.getPinList.indexOf(pinData);
      this.$store.state.pinList.pinListData.splice(removePinDataIndex, 1);
      this.$store.commit(
        'pinList/setPinListData',
        this.$store.state.pinList.pinListData
      );
    },
  },
};
</script>
