<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <v-form ref="area_name_form">
        <v-container>
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-text-field
                label="エリア名"
                v-model="address"
                clearable
                outlined
                :rules="area_search"
              >
                <template v-slot:append-outer>
                  <v-btn dark :color="search_button_color" @click="onSearch">
                    <v-icon>
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-form ref="area_form">
        <v-container>
          <v-row>
            <v-col cols="6" class="pl-0 py-0">
              <v-text-field
                v-model="lat"
                :rules="required"
                label="Latitude"
                clearable
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pr-0 py-0">
              <v-text-field
                v-model="long"
                :rules="required"
                label="Longitude"
                clearable
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pl-0 py-0">
              <v-text-field
                v-model="janame"
                :rules="required"
                label="和名"
                clearable
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pr-0 py-0">
              <v-text-field
                v-model="enname"
                :rules="required"
                label="英名"
                clearable
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0">
              <v-combobox
                v-model='type'
                :items='items'
                :rules='required'
                label='属性'
                filled
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-col>
    <v-col cols="12" class="d-flex justify-space-between">
      <v-btn class="ma-1" filled min-width="100px" @click="add">
        Add
      </v-btn>
      <input
        style="display: none"
        ref="input"
        type="file"
        accept="text/csv"
        @change="selectedFile($event)"
        id="input"
      />
      <v-btn class="ma-1" filled color="primary" @click="loadCSV">
        CSVから読込
      </v-btn>
      <v-btn class="ma-1" filled color="error" @click="clearPinList">
        Clear List All
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import colors from './../Const';
import store from '../../store';

export default {
  mounted() {
    this.$gmapApiPromiseLazy().then(() => {
      this.geocoder = new window.google.maps.Geocoder();
    });
  },
  data() {
    return {
      search_button_color: colors.primaryColor,
      error_color: colors.errorColor,
      type: '',
      items: ['駅','バス停','PoI','交差点'],
      address: '',
      lat: '',
      long: '',
      janame: '',
      enname: '',
      geocoding_error: false,
      success: false,
      area_search: [
        v => !!v || 'Name is required',
        v => (!!v && !this.geocoding_error) || 'Geocoding error',
      ],
      required: [v => !!v || 'Name is required'],
    };
  },
  methods: {
    loadCSV() {
      this.$refs.input.click();
    },
    selectedFile(e) {
      store.dispatch('pinList/loadCSV', { e });
    },
    clearPinList() {
      store.dispatch('pinList/clearPinList');
    },
    onSearch() {
      this.empty_error = false;
      this.geocoding_error = false;
      if (!this.address) {
        this.empty_error = true;
      } else {
        this.geocoder.geocode(
          {
            address: this.address,
          },
          results => {
            if (window.google.maps.GeocoderStatus.OK === 'OK') {
              if (results.length > 0) {
                console.log(results[0]);
                this.lat = results[0].geometry.location.lat();
                this.long = results[0].geometry.location.lng();
                this.janame = this.address;
              } else {
                this.geocoding_error = true;
                // console.log(!(this.geocoding_error))
                // console.log(this.$refs.area_name_form.validate())
              }
            }
          }
        );
      }
    },
    add() {
      if (this.$refs.area_form.validate()) {
        // すべてのバリデーションが通過したときのみ
        this.success = true;
        const newPinData = {
          Lat: this.lat,
          Long: this.long,
          属性: this.type,
          和名: this.janame,
          英名: this.enname,
        };
        // 新しいPinをpinListDataに追加
        this.$store.state.pinList.pinListData.push(newPinData);
        // 更新
        this.$store.commit(
          'pinList/setPinListData',
          this.$store.state.pinList.pinListData
        );
        // formのリセット
        this.$refs.area_form.reset();
        this.$refs.area_name_form.reset();
        this.empty_error = false;
      } else {
        this.success = false;
        // console.log(this.required)
      }
    },
    clear() {
      this.$refs.area_form.reset();
      this.$refs.area_name_form.reset();
      this.empty_error = false;
    },
  },
};
</script>

<style lang="scss">
// v-text-field{
//   // width: 100%;

// }
</style>
