import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import firebase from '../src/firebase/';
import * as VueGoogleMaps from 'vue2-google-maps';

firebase.init();
store.dispatch('auth/authAction');

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_TOKEN,
    libraries: 'places,drawing,visualization',
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
